import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import { fromEvent } from 'file-selector';
import { getMetadata } from 'utils/getMetadata';
import { MetadataNameEnumType } from '__generated__/queries-photo';

// handleDragItem function sets up the drag transfer data with selected photos by serializing thier informations (url, contentType ...)
// thoses information will be deserialized by the receiver side (in handleDropEvents callback function)
export const handleDragItem = (
  selectedPootos: SearchPhotosPhoto[],
  e?: React.DragEvent<HTMLDivElement>,
) => {
  if (!selectedPootos.length || !e) {
    return;
  }

  const files = selectedPootos.map((selection) => ({
    url: selection.downloadUrl,
    contentType: selection.contentType,
    filename:
      getMetadata(MetadataNameEnumType.IdPhotoPrisma, selection) ||
      'unknown.jpg',
  }));

  if (files.length > 0) {
    e?.dataTransfer.items.add(JSON.stringify(files), 'Files');
  }
};

// handleDropEvents function transform drop event into files by resolving drop event object and fetch files urls
export const handleDropEvents = async (e: any) => {
  if (e?.type !== 'drop') {
    return [];
  }

  const evt = e as React.DragEvent<HTMLElement>;
  const files = [fromEvent(evt)];
  const { items } = evt.dataTransfer;
  for (const item of items) {
    switch (item.type) {
      // use-dropzone accept only files of "Files" type
      // our cross drag event is of type "Files" but with a custom payload
      // try identify and parse it, otherwize ignore it
      case 'Files':
        await new Promise((resolve, reject) => {
          item.getAsString(async (str) => {
            try {
              const parsed = JSON.parse(str);
              const parsedList = Array.isArray(parsed) ? parsed : [parsed];
              for (const file of parsedList) {
                if (typeof file?.['url'] === 'string') {
                  files.push(
                    new Promise((resolveFile, rejectFile) => {
                      return fetch(file.url)
                        .then((res) =>
                          res
                            .blob()
                            .then((blob) => {
                              resolveFile([
                                new File(
                                  [blob],
                                  file?.['filename'],
                                  file?.['contentType'] && {
                                    type: file.contentType,
                                  },
                                ),
                              ]);
                            })
                            .catch(rejectFile),
                        )
                        .catch(rejectFile);
                    }),
                  );
                }
              }

              resolve(null);
            } catch (err) {
              reject(err);
            }
          });
        });
        break;
      default:
        break;
    }
  }

  return (await Promise.all(files)).flatMap((f) => f).filter(Boolean) as File[];
};

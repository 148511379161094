import { Grid, MenuItem, Typography } from '@mui/material';
import React, { CSSProperties, FC } from 'react';
import { Field, FieldRenderProps, FormSpy } from 'react-final-form';
import { useGetPrintHeadingByPrintIssue } from '../../apollo/queries/getPrintHeadingByPrintIssueQuery.topic.graphql';
import { useGetPrintIssuesByPrintPublication } from '../../apollo/queries/getPrintIssuesByPrintPublication.topic.graphql';
import { useGetPrintPublicationByUnit } from '../../apollo/queries/getPrintPublicationByUnit.topic.graphql';
import { AssignmentFormModel } from '../../pages/SubjectForm/Form/dataTransformer';
import { PrintSubjectFormModel } from '../../pages/SubjectForm/PrintTemplate/dataTransformer';
import { sortPrintIssues } from '../../utils/sortPrintIssues';
import { TemplateType } from '../../__generated__/queries-topic';
import { Select } from '../FinalFormMaterial/Select';
import { useStyles } from './styles';

interface AssignmentProps {
  assignmentIndex: number;
  assignment: AssignmentFormModel;
  assignmentWrapperStyle?: CSSProperties;
  websiteAvailable?: boolean;
  unitId: string;
  excludedIssues?: string[];
}

export const Assignment: FC<AssignmentProps> = ({
  unitId,
  assignment,
  websiteAvailable,
  excludedIssues = [],
  assignmentIndex,
}) => {
  const classes = useStyles();
  const {
    data: publicationData,
    loading: publicationLoading,
  } = useGetPrintPublicationByUnit({ unitId });
  const printPublicationId =
    assignment.supportId &&
    assignment.supportId.startsWith('print:') &&
    assignment.supportId?.split(':')[1];
  const {
    data: issuesData = null,
    loading: issuesLoading = false,
  } = useGetPrintIssuesByPrintPublication({
    id: printPublicationId || '',
    excludeIds: excludedIssues,
  });
  const {
    data: printHeadingData,
    loading: printHeadingLoading,
  } = useGetPrintHeadingByPrintIssue({
    id: assignment.printIssueId || '',
  });

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <FormSpy>
        {(form) => {
          const typedForm: { values: PrintSubjectFormModel } = form as any;
          if (publicationLoading) {
            return (
              <Grid item className={classes.selectField}>
                <Typography variant="subtitle1">CHARGEMENT</Typography>
              </Grid>
            );
          }
          if (!publicationData) {
            return (
              <Grid item className={classes.selectField}>
                <Typography variant="subtitle1">ERREUR</Typography>
              </Grid>
            );
          }
          const showWebsites =
            websiteAvailable !== undefined
              ? websiteAvailable
              : !(assignment.rawArticle && assignment.rawArticle.id); // assignment with rawArticle attached can't change to website
          return (
            <>
              <Grid item className={classes.selectField}>
                <Field
                  data-cy="select-supportId"
                  component={Select as FC<FieldRenderProps>}
                  name={`assignments[${assignmentIndex}].supportId`}
                  variant="white"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    form.mutators.update('assignments', assignmentIndex, {
                      ...typedForm.values.assignments[assignmentIndex],
                      supportId: e.target.value,
                      printIssueId: null,
                      printHeadingId: null,
                    });
                  }}
                >
                  <MenuItem key="no-choice" value="">
                    Support
                  </MenuItem>
                  {publicationData.units.reduce((acc, unit) => {
                    return acc.concat(
                      unit.brands.reduce((brandAcc, brand) => {
                        return brandAcc
                          .concat(
                            brand.printPublications.map((printPublication) => (
                              <MenuItem
                                key={printPublication.id}
                                value={`print:${printPublication.id}`}
                              >
                                {printPublication.title}
                              </MenuItem>
                            )),
                          )
                          .concat(
                            showWebsites
                              ? brand.websites.map((website) => (
                                  <MenuItem
                                    key={website.key}
                                    value={`web:${website.id}:${website.key}`}
                                  >
                                    WEB - {website.title}
                                  </MenuItem>
                                ))
                              : [],
                          );
                      }, [] as JSX.Element[]),
                    );
                  }, [] as JSX.Element[])}
                </Field>
              </Grid>
              {assignment.supportId ? (
                assignment.supportId.startsWith('print:') ? (
                  issuesLoading ? (
                    <Grid item className={classes.selectField}>
                      <Typography variant="subtitle1">CHARGEMENT</Typography>
                    </Grid>
                  ) : (
                    <>
                      <Grid item className={classes.selectField}>
                        <Field
                          data-cy="select-printIssueId"
                          variant="white"
                          component={Select as FC<FieldRenderProps>}
                          selectProps={{
                            fullWidth: true,
                            disabled:
                              !issuesData || !issuesData.printPublication,
                          }}
                          name={`assignments[${assignmentIndex}].printIssueId`}
                          onChange={(
                            e: React.ChangeEvent<HTMLSelectElement>,
                          ) => {
                            form.mutators.update(
                              'assignments',
                              assignmentIndex,
                              {
                                ...typedForm.values.assignments[
                                  assignmentIndex
                                ],
                                printIssueId: e.target.value,
                                printHeadingId: null,
                              },
                            );
                          }}
                        >
                          <MenuItem key="no-choice" value="">
                            n°
                          </MenuItem>
                          {issuesData &&
                            issuesData.printPublication &&
                            issuesData.printPublication.printIssues &&
                            sortPrintIssues(
                              issuesData.printPublication.printIssues,
                            ).map((issue) => (
                              <MenuItem key={issue.id} value={issue.id}>
                                {issue.title}
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>
                      {printHeadingLoading ? (
                        <Grid item className={classes.selectField}>
                          <Typography variant="subtitle1">
                            CHARGEMENT
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid item className={classes.selectField}>
                          <Field
                            data-cy="select-printHeadingId"
                            variant="white"
                            component={Select as FC<FieldRenderProps>}
                            selectProps={{
                              fullWidth: true,
                              disabled:
                                !printHeadingData ||
                                !printHeadingData.printIssue,
                            }}
                            name={`assignments[${assignmentIndex}].printHeadingId`}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>,
                            ) => {
                              const printHeading = printHeadingData?.printIssue?.printHeadings?.find(
                                (item) => item.id === e.target.value,
                              );
                              const printTemplate =
                                printHeading?.printTemplates?.[0]
                                  ?.printTemplate;
                              form.mutators.update(
                                'assignments',
                                assignmentIndex,
                                {
                                  ...typedForm.values.assignments[
                                    assignmentIndex
                                  ],
                                  printHeadingId: e.target.value,
                                  autoPrototype: printHeading?.autoPrototype,
                                  printTemplateId:
                                    printTemplate?.type ===
                                    TemplateType.ARTICLE_TEMPLATE
                                      ? printTemplate?.id
                                      : '',
                                },
                              );
                            }}
                          >
                            <MenuItem key="no-choice" value="">
                              Têtière
                            </MenuItem>
                            {printHeadingData &&
                              printHeadingData.printIssue &&
                              printHeadingData.printIssue.printHeadings.map(
                                (printHeading) => (
                                  <MenuItem
                                    key={printHeading.id}
                                    value={printHeading.id}
                                  >
                                    {printHeading.title}
                                  </MenuItem>
                                ),
                              )}
                          </Field>
                        </Grid>
                      )}
                    </>
                  )
                ) : (
                  <Typography variant="caption" className={classes.noIssueText}>
                    &nbsp;Les affectations Web n'ont pas de parutions ou de
                    têtières associées
                  </Typography>
                )
              ) : (
                <Typography variant="caption" className={classes.noIssueText}>
                  &nbsp;Sélectionnez un support
                </Typography>
              )}
            </>
          );
        }}
      </FormSpy>
    </Grid>
  );
};
